import React from 'react';
import ruler from '../assets/images/ruler.png';
import weight from '../assets/images/weight.png';
import diameter from '../assets/images/diameter.png';
import people from '../assets/images/people.png';
import camera from '../assets/images/camera.png';
import plug from '../assets/images/power-plug.png';
import MachinesInclusions from './MachinesInclusions';

const Inclusions = () => {
  return (
    <>
      <div className='flex flex-row w-full items-center justify-center gap-8'>
        <div className='flex flex-col'>
          <MachinesInclusions
            image={ruler}
            alt={'icon of ruler'}
            title={'Platform Height'}
            details={'11"'}
          />
          <MachinesInclusions
            image={diameter}
            alt={'icon of diameter'}
            title={'Platform Diameter'}
            details={'80cm / 100cm'}
          />
          <MachinesInclusions
            image={people}
            alt={'icon of people'}
            title={'Capacity'}
            details={'1 - 3 / 4 - 6 guests'}
          />
        </div>
        <div className='flex flex-col'>
          <MachinesInclusions
            image={weight}
            alt={'icon of weight'}
            title={'Load Capacity'}
            details={'330kg / 400kg'}
          />
          <MachinesInclusions
            image={ruler}
            alt={'icon of ruler'}
            title={'Max Adjustable Height'}
            details={'190cm'}
          />
          <MachinesInclusions
            image={camera}
            alt={'icon of camera'}
            title={'Equipped With'}
            details={'Go Pro / Camera / iPad Air'}
          />
        </div>
      </div>
      <div className='flex justify-center'>
        <MachinesInclusions
          image={plug}
          alt={'icon of power plug'}
          details={'All machines will need a power source'}
        />
      </div>
    </>
  );
};

export default Inclusions;
