import React from 'react';
import { useNavigate } from 'react-router-dom';
import product from '../assets/images/purpleProduct.jpg';
import mainVideo from '../assets/video/mainVideo.mp4';
import heroVideo from '../assets/video/heroVideo.mp4';

const Main = () => {
  const navigate = useNavigate();

  const goToPackages = () => {
    navigate('/packages');
  };

  return (
    <div className='flex flex-col mt-6 justify-center items-center'>
      <div className='flex flex-col lg:flex-colum mb-6 items-center'>
        {/* <div className='mb-0 md:mb-6 lg:mb-0'> */}
        <p className='text-primary text-3xl font-semibold text-center mb-2 w-[350px] md:w-[500px]'>
          EXACTLY WHAT YOUR EVENT NEEDS!
        </p>
        <p className='text-lg font-light w-[320px] md:w-[500px] text-center pt-6 mr-0'>
          Take your event to the next level with Perth’s premier revolving 360
          video and photo booth.
        </p>
        <p className='text-lg font-light w-[320px] md:w-[500px] text-center pt-6 mr-0'>
          We know. Game Changer – capture those insta-worthy moments and turn
          them into videos and photos that become forever memories you can keep.
        </p>
        <p className='text-lg font-light w-[320px] md:w-[500px] text-center pt-6 mr-0'>
          As seen with celebrities such as Kim Kardashian, Snoop Dogg and J-Lo.
        </p>
        <p className='text-lg font-light w-[320px] md:w-[500px] text-center pt-6 mr-0'>
          Revolve 360 Perth is the perfect entertainment video booth experience
          for your social, formal or corporate event. We bring the fun factor
          that will take your event from good, to great.
        </p>
      </div>

      <div className='hidden md:flex md:mt-6'>
        <img src={product} alt='rotating camera platform' />
        {/* </div> */}
      </div>

      <div className='flex flex-col lg:flex-colum items-center mt-6 mb-6'>
        <h1 className='text-primary text-3xl font-semibold mb-2 w-[350px] text-center md:text-center md:w-[500px] lg:w-full'>
          HOW DOES IT WORK?
        </h1>
        <div className='flex flex-col lg:flex-column pt-6 gap-6 items-center'>
          <p className='text-lg font-light w-[320px] md:w-[500px] text-center m-0'>
            Simple.
            <br /> Stand on the podium and do your thing, while the revolving
            camera spins around you snapping and filming away. Within seconds,
            you’ll have a high-quality video in your hot little hands – ready to
            share with the world! The videos can be shared in real-time or as
            slow motion.
          </p>
          <video
            className='w-[320px] md:w-[500px] rounded-md'
            src={heroVideo}
            autoPlay
            loop
            muted
          />
          <p className='text-lg font-light w-[320px] md:w-[500px] text-center m-0'>
            All you need to do is book and we bring the goods – providing a full
            end-to-end service, including an attendant and props to help you get
            the best out of your experience and capture amazing video footage.
          </p>
        </div>
      </div>
      <button
        className='mt-2 mb-6 pb-0 flex border-2 border-white justify-center items-center bg-primary w-[200px] rounded-md text-white font-semibold h-[44px] hover:bg-white hover:text-primary hover:border-primary transition-all delay-100'
        onClick={goToPackages}
      >
        VIEW PACKAGES
      </button>
    </div>
  );
};

export default Main;
