import React from 'react';

const SectionTitle = (props) => {
  return (
    <div className='flex items-center justify-center font-semibold text-primary w-full bg-secondary h-[48px] text-2xl'>
      <h1 className='hidden md:flex'>{props.title}</h1>
      <h1 className='md:hidden'>{props.shortTitle}</h1>
    </div>
  );
};

export default SectionTitle;
