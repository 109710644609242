import React from 'react';

const Package = (props) => {
  return (
    <div className='group flex flex-col w-[180px] bg-secondary rounded-md py-[18px] hover:bg-primary first:hover:text-black hover:cursor-pointer transition-all'>
      <p className='text-primary font-semibold group-hover:text-black'>
        {props.type}
      </p>
      <p>{props.cost}</p>
    </div>
  );
};

export default Package;
