import React from 'react';

const ItemsWrapper = ({ children }) => {
  return (
    <div className='flex flex-row w-full items-center justify-center gap-8'>
      {children}
    </div>
  );
};

export default ItemsWrapper;
