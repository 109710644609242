import React from 'react';
import ContactForm from '../components/ContactForm';
import Hero from '../components/Hero';
import Main from '../components/Main';

const Home = () => {
  return (
    <div className='flex flex-col items-center min-h-screen'>
      <Hero />
      <Main />
      <ContactForm />
    </div>
  );
};

export default Home;
