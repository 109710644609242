import { useEffect } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PackagesPage from './pages/Packages';
import Footer from './components/Footer';
import Contact from './pages/Contact';

function App() {
  useEffect(() => {
    document.title = 'Revolve 360 Photography Perth';
  });

  return (
    <div className='App'>
      <NavBar />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/packages' element={<PackagesPage />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Home />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
