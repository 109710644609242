import React from 'react';

const PackageItem = (props) => {
  return (
    <div className='flex flex-col items-center content-center justify-center h-[120px] w-[180px] my-2'>
      <img
        src={props.image}
        alt={props.alt}
        className='h-[50px] w-[50px] mb-2'
      />
      <p>{props.description}</p>
    </div>
  );
};

export default PackageItem;
