import React from 'react';
import PackageItem from './PackageItem';
import backdrop from '../assets/images/backdrop.png';
import thumbdrive from '../assets/images/flash-drive.png';
import clock from '../assets/images/clock.png';
import monitor from '../assets/images/share.png';
import music from '../assets/images/musical-notes.png';
import flowers from '../assets/images/flowers.png';
import ItemsWrapper from './ItemsWrapper';

const AdditionalItems = () => {
  const goToAcarastyling = () => {
    window.location.replace(
      'https://www.acarastyling.com.au/product-category/arbours-and-backdrops/'
    );
  };

  return (
    <div className='my-4'>
      <ItemsWrapper>
        <div className='flex flex-col'>
          <PackageItem
            image={backdrop}
            alt='icon of a backdrop'
            description='backdrop up to value of $200 for $150'
          />
          <PackageItem
            image={thumbdrive}
            alt='icon of thumb drive'
            description='thumb drive $35'
          />
          <PackageItem
            image={flowers}
            alt='icon of flowers'
            description='silk flower arrangements from $50'
          />
        </div>
        <div className='flex flex-col'>
          <PackageItem
            image={clock}
            alt='icon of a clock'
            description='extra hire hours $500'
          />
          <PackageItem
            image={monitor}
            alt='icon of computer monitor'
            description='online gallery $35'
          />
          <PackageItem
            image={music}
            alt='icon of music symbol'
            description='customised music $10'
          />
        </div>
      </ItemsWrapper>
      <div>
        <p className='pb-2'>custom backdrops available from</p>
        <button
          onClick={goToAcarastyling}
          className='font-semibold bg-white border-2 border-primary text-primary rounded-md py-2 px-8'
        >
          acarastyling
        </button>
      </div>
    </div>
  );
};

export default AdditionalItems;
