import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Footer = () => {
  const goToFacebook = () => {
    window.location.replace('https://www.facebook.com/revolve360perth');
  };

  const goToInstagram = () => {
    window.location.replace('https://www.instagram.com/revolve360perth/');
  };

  return (
    <footer className='flex flex-col md:flex-row gap-2 justify-center items-center h-32 md:h-16 bg-black text-white'>
      <div>
        <p className='md:border-r-2 md:pr-2'>© 2021 REVOLVE 360 PERTH</p>
      </div>
      <div className='flex flex-col md:flex-row gap-2 md:border-r-2 md:pr-2'>
        <p className='md:border-r-2 pr-2'>Mobile: 0427 616 499</p>
        <p>Email: hello@revolve360perth.com.au</p>
      </div>
      <div className='flex gap-2'>
        <FontAwesomeIcon
          icon={faFacebook}
          className='hover:text-primary hover:cursor-pointer'
          onClick={goToFacebook}
        />
        <FontAwesomeIcon
          icon={faInstagram}
          className='hover:text-primary hover:cursor-pointer'
          onClick={goToInstagram}
        />
      </div>
    </footer>
  );
};

export default Footer;
