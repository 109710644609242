import React from 'react';
import AdditionalItems from '../components/AdditionalItems';
import AllPackagesSection from '../components/AllPackagesSection';
import Inclusions from '../components/Inclusions';
import SectionTitle from '../components/SectionTitle';
import Packages from '../components/Packages';
import ContactForm from '../components/ContactForm';

const PackagesPage = () => {
  return (
    <div>
      <div className='mt-6 flex flex-col items-center'>
        <p className='font-semibold text-primary w-full h-[48px] text-2xl'>
          PACKAGES
        </p>
        <p className='text-lg w-[300px] font-light sm:w-[300px] md:w-[500px]'>
          WOW your guests with one of our all-inclusive packages that will make
          your event stand-out to the crowd.
          <br />
          <br /> We use cutting edge technology to capture the fun with 360
          video footage that you and your guests can love forever.
        </p>

        <p className='mt-4 text-lg w-[300px] font-light sm:w-[300px] md:w-[500px]'>
          Check out our packages and inclusions below.
        </p>
      </div>
      <Packages />
      <SectionTitle
        title={'Machine Specifications'}
        shortTitle={'Machine Specifications'}
      />
      <Inclusions />
      <SectionTitle
        title={'All Packages Include'}
        shortTitle={'All Packages Include'}
      />
      <AllPackagesSection />
      <SectionTitle
        title={'Additional Items'}
        shortTitle={'Additional Items'}
      />
      <AdditionalItems />
      <ContactForm />
    </div>
  );
};

export default PackagesPage;
