import React from 'react';
import PackageItem from './PackageItem';
import attendant from '../assets/images/attendant.png';
import degrees360 from '../assets/images/360-degrees.png';
import branding from '../assets/images/branding.png';
import selfie from '../assets/images/selfie.png';
import balloons from '../assets/images/balloons.png';
import lightbulb from '../assets/images/light-bulb.png';
import shipped from '../assets/images/shipped.png';

const AllPackagesSection = () => {
  return (
    <>
      <div className='flex flex-row w-full items-center justify-center gap-8'>
        <div className='flex flex-col'>
          <PackageItem
            image={attendant}
            alt='a woman attendant icon'
            description='two attendants'
          />
          <PackageItem
            image={branding}
            alt='pen and paper icon'
            description='custom branding overlay on photos'
          />
          <PackageItem
            image={balloons}
            alt='icon of balloons'
            description='themed party props'
          />
        </div>
        <div className='flex flex-col'>
          <PackageItem
            image={degrees360}
            alt='360 degrees icon'
            description='360 video stage'
          />
          <PackageItem
            image={selfie}
            alt='hand holding phone'
            description='access to unlimited slow motion videos'
          />
          <PackageItem
            image={lightbulb}
            alt='lightbulb icon'
            description='LED video lighting (2 options)'
          />
        </div>
      </div>
      <div className='flex justify-center'>
        <PackageItem
          image={shipped}
          alt='delivery van icon'
          description='delivery set-up and take-down'
        />
      </div>
    </>
  );
};

export default AllPackagesSection;
