import React, { useState } from 'react';
import { send } from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
const Contact = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [toSend, setToSend] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    package: '3 hours ($1000)',
    details: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(toSend);
    send('test', 'template_h7m5po9', toSend, 'user_5MJbAEihwDWeuK0XfPuHe')
      .then((response) => {
        console.log('success!', response.status, response.text);
        setEmailSent(true);
      })
      .catch((err) => {
        console.log('failed', err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const goToFacebook = () => {
    window.location.replace('https://www.facebook.com/revolve360perth');
  };

  const goToInstagram = () => {
    window.location.replace('https://www.instagram.com/revolve360perth/');
  };

  return (
    <div className='flex flex-col mb-6 mt-6 items-center min-h-screen'>
      {!emailSent ? (
        <>
          <div className='mb-6'>
            <h1 className='text-2xl font-bold text-primary'>CONTACT US</h1>
            <p className='w-[400px] xs:w-[400px] sm:w-[420px]'>
              Fill out the form below to contact us about booking!
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className='flex flex-col items-center justify-center py-6 rounded-md w-[360px] bg-secondary'
          >
            <div className='flex flex-col text-left w-[80%] contact-form-item'>
              <input
                className='input'
                type='text'
                name='name'
                value={toSend.name}
                placeholder='Your Name'
                onChange={handleChange}
              />
            </div>

            <div className='flex flex-col text-left w-[80%] contact-form-item'>
              <input
                className='input'
                type='email'
                name='email'
                placeholder='Your Email'
                value={toSend.email}
                onChange={handleChange}
              />
            </div>

            <div className='flex flex-col text-left w-[80%] contact-form-item'>
              <input
                className='input'
                type='text'
                name='phone'
                onChange={handleChange}
                value={toSend.phone}
                placeholder='Phone Number'
              />
            </div>

            <div className='flex flex-col text-left w-[80%] contact-form-item'>
              <input
                className='input'
                name='date'
                type='date'
                value={toSend.date}
                onChange={handleChange}
              />
            </div>

            <div className='flex flex-col text-left w-[80%] contact-form-item'>
              <select
                className='input'
                name='package'
                id='package'
                value={toSend.package}
                onChange={handleChange}
              >
                <option value='3 hours ($1000)'>3 hours ($1000)</option>
                <option value='4 hours ($1500)'>4 hours ($1500)</option>
                <option value='5 hours ($2000)'>5 hours ($2000)</option>
                <option value='6 hours ($2500)'>6 hours ($2500)</option>
              </select>
            </div>

            <div className='flex flex-col text-left w-[80%] pb-[12px]'>
              <textarea
                name='details'
                className='h-32 px-4 py-[2px] placeholder:text-black'
                placeholder='Additional Information (time of day etc)'
                value={toSend.details}
                onChange={handleChange}
              />
            </div>

            <button className='pb-0 flex justify-center items-center bg-primary w-[80%] rounded-md text-white font-semibold h-[44px] hover:bg-white hover:text-primary transition-all delay-100'>
              SEND
            </button>
          </form>{' '}
          <div className='text-center pt-3'>
            <p>Mobile: 0427 616 499</p>
            <p>Email: hello@revolve360perth.com.au</p>

            <div className='flex justify-around pt-3 text-3xl'>
              <FontAwesomeIcon
                icon={faFacebook}
                className='hover:text-primary hover:cursor-pointer'
                onClick={goToFacebook}
              />
              <FontAwesomeIcon
                icon={faInstagram}
                className='hover:text-primary hover:cursor-pointer'
                onClick={goToInstagram}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <h1 className='text-2xl'>
            You have successfully requested to book! We will try respond within
            1 working day.
          </h1>
        </>
      )}
    </div>
  );
};

export default Contact;
