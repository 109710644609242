import React from 'react';

const NavBarText = (props) => {
  return (
    <p
      className='font-light text-white border-b-2 border-b-black hover:cursor-pointer hover:border-b-primary'
      onClick={props.onClick}
    >
      {props.title}
    </p>
  );
};

export default NavBarText;
