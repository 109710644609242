import React from 'react';

const MachinesInclusions = (props) => {
  return (
    <div className='flex flex-col items-center content-center justify-center h-[120px] w-[180px] my-2'>
      <img
        className='h-[50px] w-[50px] mb-2'
        src={props.image}
        alt={props.alt}
      />
      <p className='font-semibold text-md'>{props.title}</p>
      <p className='text-sm'>{props.details}</p>
    </div>
  );
};

export default MachinesInclusions;
