import React from 'react';
import heroVideo from '../assets/video/heroVideo.mp4';
import mainVideo from '../assets/video/mainVideo.mp4';

const Hero = () => {
  return (
    <div className='flex w-full bg-primary max-h-screen screen items-center justify-center overflow-hidden'>
      <div className='text-2xl text-primary font-bold items-center align-middle '>
        <div className='relative text-center'>
          <video
            className='content-cover'
            src={mainVideo}
            autoPlay
            loop
            muted
          />
          <div className='h-[100%] w-[100%] bg-heroBlack absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'></div>
          <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-3xl w-[80%] sm:w-[80%] md:w-[800px] lg:w-[800px] lg:text-4xl stroke'>
            <p className=''>
              INTRODUCING THE HOTTEST IN PHOTO BOOTH TECHNOLOGY
            </p>
            <br />
            <p>REVOLVE 360 PERTH</p>
            <br />
            <p>CAPTURING ALL YOUR SPECIAL MOMENTS FROM A 360 DEGREE VIEW!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
