import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarText from './NavBarText';
import logo from '../assets/images/revolveLogo.png';

const NavBar = () => {
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/');
  };

  const goToPackagesPage = () => {
    navigate('/packages');
  };

  const goToContactPage = () => {
    navigate('/contact');
  };

  return (
    <div className='flex flex-row py-4 h-[115px] bg-black w-full'>
      <div className='flex flex-row justify-between py-4 pr-2 items-center w-full 2xl:px-64 2xl:text-xl'>
        <img src={logo} alt='resolve 360 perths logo' className='h-[100px]' />
        <div className='flex flex-row gap-4 font-semibold'>
          <NavBarText title={'HOME'} onClick={goToHomePage} />
          <NavBarText title={'PACKAGES'} onClick={goToPackagesPage} />
          <NavBarText title={'CONTACT'} onClick={goToContactPage} />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
