import React from 'react';
import Package from './Package';
import redProductFlipped from '../assets/images/redProductFlipped.png';
import secondMachine from '../assets/images/revolveOriginalMachineFix.png';

import { useNavigate } from 'react-router-dom';
import SectionTitle from './SectionTitle';

const Packages = () => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col items-center gap-8'>
      <h2 className='mt-6 font-semibold text-xl text-primary'>
        2022 Introductory Offer
      </h2>
      <div className='flex gap-8'>
        <Package type={'3 hours'} cost={'$1000'} />
        <Package type={'4 hours'} cost={'$1500'} />
      </div>
      <div className='flex gap-8'>
        <Package type={'5 hours'} cost={'$2000'} />
        <Package type={'6 hours'} cost={'$2500'} />
      </div>

      <button
        onClick={() => navigate('/contact')}
        className='mt-2 pb-0 flex border-2 border-white justify-center items-center bg-primary w-[200px] rounded-md text-white font-semibold h-[44px] hover:bg-white hover:text-primary hover:border-primary transition-all delay-100'
      >
        BOOK NOW!
      </button>

      <SectionTitle
        title={'2 Styles and Sizes of Platforms to choose from'}
        shortTitle={'2 Styles and Sizes'}
      ></SectionTitle>

      <div className='flex gap-6'>
        <img
          className='mb-6 w-[120px] h-[166px] md:w-[240px] md:h-[332px]'
          src={redProductFlipped}
          alt='360 platform'
        />
        <img
          className='mb-6 w-[120px] h-[166px] md:w-[240px] md:h-[332px]'
          src={secondMachine}
          alt='360 platform'
        />
      </div>
    </div>
  );
};

export default Packages;
